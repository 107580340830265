import React, { Component } from 'react';
import { Grid, Cell, Card, CardTitle, CardText, CardActions, Button } from 'react-mdl';
import FontAwesome from 'react-fa';

export default class AdultSundaySchool extends Component {
  render() {

    return (
      <div>
        <div style={{width: '90%', margin: 'auto'}}>

          <Grid>
            <Cell col={12}>
              <Card shadow={0} style={{width: '100%', margin: 'auto'}}>
                <CardTitle style={{ color: '#fff', height: '150px',  background: 'url(/images/lighthouse.jpg) center / cover'}}>成人主日學 Adult Sunday School (粵語/国语)</CardTitle>
                <CardText>
                  <p>
                    &emsp;<FontAwesome name='calendar'/> : &emsp;逢第二、三、四主日，晚上八時至九時 <br/>
                    &emsp;<FontAwesome name='map-marker'/> : &emsp;Zoom  或疫後 Portswood Church <br/>
                    &emsp;<FontAwesome name='address-card'/> : &emsp;衞傳道 Pastor Henry <br/>
                    &emsp;<FontAwesome name='envelope'/> : &emsp;henry.wai@sccc.org.uk <br/>
                  </p>
                  <h6>
                  教會將於2025年二月份開始新一季的成人主日學課程，詳細的內容如下:<br/><br/>

                  
&emsp;1) 課程：廣東話 --- 查經 : 帖撒羅尼迦書信 <br/>
&emsp;&emsp;講師：衞師母 <br/>
&emsp;&emsp;課程簡介：<br/>
&emsp;&emsp;&emsp;帖撒羅尼迦書信主要提醒我們主再來是我們最大的盼望。今天許多信徒都忘記了這個盼望，終日勞碌，都是因為今世的思慮，並缺乏時刻儆醒的心，也不懂得以主再來的應許彼此勸勉，因此失去見證的力量。希望藉研讀此書信帶給大家對混亂世局的啟示，以正面回應對主的信心。<br/><br/>
&emsp;&emsp;時間：2025年二, 三, 四月 逢第二，三，四 主日，晚上八時至九時。<br/>
&emsp;&emsp;地點：Zoom  <br/><br/>
<br/>
                    </h6>
                </CardText>
                <CardActions border>
                  <Button colored><a href="https://forms.gle/MkuoK3ZmnjCEYETA7">Online Registration Form</a></Button>
                </CardActions>
              </Card>
            </Cell>
          </Grid>
        </div>
      </div>
    );
  }
}
